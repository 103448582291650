@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Diphylleia&family=Kaisei+HarunoUmi:wght@400;500&family=Kaushan+Script&family=Salsa&family=Shippori+Antique+B1&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Poppins:wght@200;300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400&display=swap');
#root,
body,
html {
  font-family: "'Diphylleia', serif";
  font-weight: 600;
}
