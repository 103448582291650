/* For WebKit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: white; /* color of the track (background behind the scrollbar) */
}

::-webkit-scrollbar-thumb {
  background: #F96E1E; /* color of the scrollbar thumb (the draggable handle) */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar thumb when hovered */
}