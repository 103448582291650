.main-slide {
padding-top: 30px;
}

.carousel .slider-wrapper {
    width: 100%;
   
}

.carousel .thumb {
    width: 5%;
    height: 50px;
    border: 2px solid red;
    display: none;
}
.carousel{
    text-align: center;
}
.first-letter {
    color: orangered;
    font-size: 55px;
  }